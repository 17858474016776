import TableViewIcon from '@mui/icons-material/TableView';
import WaterDropIcon from '@mui/icons-material/WaterDrop';
import DashboardIcon from '@mui/icons-material/Dashboard';
import ContentPasteSearchIcon from '@mui/icons-material/ContentPasteSearch';
import ScienceIcon from '@mui/icons-material/Science';
import TakeoutDiningIcon from '@mui/icons-material/TakeoutDining';
import KeyIcon from '@mui/icons-material/Key';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import WidgetsIcon from '@mui/icons-material/Widgets';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import DownloadIcon from '@mui/icons-material/Download';
import InstallMobileIcon from '@mui/icons-material/InstallMobile';
import Inventory2SharpIcon from '@mui/icons-material/Inventory2Sharp';
import PaidIcon from '@mui/icons-material/Paid';
import LaptopIcon from '@mui/icons-material/Laptop';
import LocalPrintshopSharpIcon from '@mui/icons-material/LocalPrintshopSharp';
import QrCode2SharpIcon from '@mui/icons-material/QrCode2Sharp';
import SearchSharpIcon from '@mui/icons-material/SearchSharp';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import FolderSpecialIcon from '@mui/icons-material/FolderSpecial';
import SettingsSuggestIcon from '@mui/icons-material/SettingsSuggest';
import HelpIcon from '@mui/icons-material/Help';
import VideoCameraBackIcon from '@mui/icons-material/VideoCameraBack';
import AssessmentIcon from '@mui/icons-material/Assessment';
import PhoneForwardedIcon from '@mui/icons-material/PhoneForwarded';
import HandymanIcon from '@mui/icons-material/Handyman';
import TokenIcon from '@mui/icons-material/Token';
import VaccinesIcon from '@mui/icons-material/Vaccines';
import DriveFileMoveRoundedIcon from '@mui/icons-material/DriveFileMoveRounded';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import useModal from '../shared/hooks/useModal/useModal';
import DownloadMobileGatewayModal from './DownloadMobileGatewayModal';

import styles from './Main.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { getUserRequest, postDownloadBillingFileRequest } from './services/actions';
import { mainPageSelector } from './services/selector';
import ErrorResponse from '../shared/ErrorResponse';
import { SelectCidDialog } from '../common/ConfirmDialog';
import { TrueAPIModal } from './TrueAPIModal/TrueAPIModal';
import { settingsSelector } from '../About/Settings/services/selector';
import { ComplianceModal } from '../common/ComplianceModal/ComplianceModal';
import { msalInstance } from '../../index';

export const Main: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { isShow, openModal, closeModal } = useModal();
  const { isShow: isShowTrueApi, openModal: openModalTrueApi, closeModal: closeModalTrueApi } = useModal();
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [open, setOpen] = useState(false);
  const [isComplianceModalShow, setIsComplianceModalShow] = useState(false);
  const [isCompilanceAccepted] = React.useState(localStorage.getItem('isComplianceAccepted'));

  const { userResponse, userErrorResponses, billingDownloadErrorResponses, isFileDownloading } =
    useSelector(mainPageSelector);
  const { trueApiErrorResponse, getKeyError } = useSelector(settingsSelector);

  const lPrintLink = process.env.REACT_APP_LPRINT_GATEWAY_DOWNLOAD_LINK;
  const reaJetLink = process.env.REACT_APP_REAPRINT_DOWNLOAD_LINK;
  const as1000Link = process.env.REACT_APP_AS1000_DOWNLOAD_LINK;
  const neriLink = process.env.REACT_APP_NERIPRINT_DOWNLOAD_LINK;
  const manualsLink = process.env.REACT_APP_MANUALS_DOWNLOAD_LINK;
  const videoLink = process.env.REACT_APP_VIDEO_DOWNLOAD_LINK;
  const toolsLink = process.env.REACT_APP_TOOLS_DOWNLOAD_LINK;

  const account = msalInstance.getAllAccounts()[0];

  useEffect(() => {
    dispatch(getUserRequest());
  }, []);

  useEffect(() => {
    const cidLC = localStorage.getItem('cid');
    if (Object.keys(userResponse).length !== 0 && userResponse.mustUsedId !== null) {
      setSelectedCompany(userResponse.cidList.find((cid: any) => cid.id === userResponse.mustUsedId)?.companyName);
    }

    if (Object.keys(userResponse).length !== 0 && userResponse.mustUsedId === null) {
      setSelectedCompany(userResponse.cidList.find((cid: any) => cid.cid === cidLC)?.companyName);
    }
  }, [userResponse]);

  useEffect(() => {
    if (Object.keys(userErrorResponses).length !== 0 && userErrorResponses.status === 403) {
      navigate('/error');
    }
  }, [userErrorResponses]);

  const onCompanyClick = () => {
    if (Object.keys(userResponse).length !== 0 && userResponse.mustUsedId !== null) {
      return undefined;
    }

    if (Object.keys(userResponse).length !== 0 && userResponse.mustUsedId === null) {
      localStorage.removeItem('cid');
      localStorage.removeItem('entraGroupId');
      localStorage.removeItem('account');
      setOpen(true);
    }
  };

  useEffect(() => {
    if (Object.keys(trueApiErrorResponse).length !== 0 && trueApiErrorResponse.status === 403) {
      navigate('/error');
    }
  }, [trueApiErrorResponse]);

  useEffect(() => {
    if (!isCompilanceAccepted) {
      setIsComplianceModalShow(true);
    }
  }, []);

  return (
    <div className={styles.wrapper}>
      {Object.keys(trueApiErrorResponse).length !== 0 ? <ErrorResponse error={trueApiErrorResponse} /> : null}
      {Object.keys(userErrorResponses).length !== 0 ? <ErrorResponse error={userErrorResponses} /> : null}
      {Object.keys(getKeyError).length !== 0 ? <ErrorResponse error={getKeyError} /> : null}
      {Object.keys(billingDownloadErrorResponses).length !== 0 ? (
        <ErrorResponse error={billingDownloadErrorResponses} />
      ) : null}
      <span className={styles.title}>
        GATEWAY{'  '}
        <span onClick={onCompanyClick}>{selectedCompany}</span>
      </span>
      <div className={styles.table}>
        <section className={styles.itemsRow}>
          <div className={styles.item}>
            <span className={styles.itemHeader}>Коди маркування</span>
            <div className={styles.itemBody} onClick={() => navigate('/import')}>
              <DownloadIcon className={styles.itemIcon} />
              <span className={styles.itemText}>Імпорт нових кодів</span>
            </div>
            <div className={styles.itemBody} onClick={() => navigate('/available')}>
              <QrCode2SharpIcon className={styles.itemIcon} />
              <span className={styles.itemText}>Банк кодів</span>
            </div>
            <div className={styles.itemBody} onClick={() => navigate('/receipts')}>
              <ReceiptLongIcon className={styles.itemIcon} />
              <span className={styles.itemText}>Рецепти</span>
            </div>
            <div className={styles.itemBody} onClick={() => navigate('/printCodes')}>
              <LocalPrintshopSharpIcon className={styles.itemIcon} />
              <span className={styles.itemText}>Завдання на друк</span>
            </div>
          </div>

          <div className={styles.item}>
            <span className={styles.itemHeader}>Довідники</span>
            <div className={styles.itemBody} onClick={() => navigate('/products')}>
              <VaccinesIcon className={styles.itemIcon} />
              <span className={styles.itemText}>Продукція</span>
            </div>
            <div className={styles.itemBody} onClick={() => navigate('/packaging')}>
              <TakeoutDiningIcon className={styles.itemIcon} />
              <span className={styles.itemText}>Пакування</span>
            </div>
            <div className={styles.itemBody} onClick={() => navigate('/withdrawals')}>
              <FolderSpecialIcon className={styles.itemIcon} />
              <span className={styles.itemText}>Причини вилучень</span>
            </div>
            <div className={styles.itemBody} onClick={() => navigate('/unit_templates')}>
              <WaterDropIcon className={styles.itemIcon} />
              <span className={styles.itemText}>Шаблони продукції (Level 0)</span>
            </div>
            <div className={styles.itemBody} onClick={() => navigate('/box_templates')}>
              <Inventory2SharpIcon className={styles.itemIcon} />
              <span className={styles.itemText}>Шаблони ящиків (Level 2)</span>
            </div>
            <div className={styles.itemBody} onClick={() => navigate('/pallet_templates')}>
              <DashboardIcon className={styles.itemIcon} />
              <span className={styles.itemText}>Шаблони палет (Level 3)</span>
            </div>
          </div>

          <div className={styles.item}>
            <span className={styles.itemHeader}>Звіти</span>
            <div className={styles.itemBody} onClick={() => navigate('/searchCodes')}>
              <SearchSharpIcon className={styles.itemIcon} />
              <span className={styles.itemText}>Пошук по кодам маркувань</span>
            </div>
            <div className={styles.itemBody} onClick={() => navigate('/importCodesReport')}>
              <AssessmentIcon className={styles.itemIcon} />
              <span className={styles.itemText}>Звіт з імпорту кодів</span>
            </div>
            <div className={styles.itemBody} onClick={() => navigate('/codes_report')}>
              <WidgetsIcon className={styles.itemIcon} />
              <span className={styles.itemText}>Звіт з виробництва</span>
            </div>
            <div className={styles.itemBody} onClick={() => navigate('/shipment')}>
              <LocalShippingIcon className={styles.itemIcon} />
              <span className={styles.itemText}>Звіт з відвантаження</span>
            </div>
            <div className={styles.itemBody} onClick={() => navigate('/invoices_report')}>
              <DriveFileMoveRoundedIcon className={styles.itemIcon} />
              <span className={styles.itemText}>Звіт по накладним</span>
            </div>
            <div className={styles.itemBody} onClick={() => navigate('/series_report')}>
              <TableViewIcon className={styles.itemIcon} />
              <span className={styles.itemText}>Звіт по серіям</span>
            </div>
            <div className={styles.itemBody} onClick={() => navigate('/withdrawal')}>
              <ScienceIcon className={styles.itemIcon} />
              <span className={styles.itemText}>Звіт з вилучень</span>
            </div>
            <div className={styles.itemBody} onClick={() => navigate('/audittrail_report')}>
              <ContentPasteSearchIcon className={styles.itemIcon} />
              <span className={styles.itemText}>Звіт Audit Trail</span>
            </div>
          </div>
        </section>
        <section className={styles.itemsRow}>
          <div className={styles.item}>
            <span className={styles.itemHeader}>Службові</span>
            <div className={styles.itemBody} onClick={() => navigate('/licensedDevices')}>
              <KeyIcon className={styles.itemIcon} />
              <span className={styles.itemText}>Ліцензовані підключення</span>
            </div>
            <div className={styles.itemBody} onClick={() => navigate('/processes')}>
              <AccountTreeIcon className={styles.itemIcon} />
              <span className={styles.itemText}>Фонові процеси</span>
            </div>
            <div className={styles.itemBody} onClick={() => navigate('/settings')}>
              <SettingsSuggestIcon className={styles.itemIcon} />
              <span className={styles.itemText}>Системні параметри</span>
            </div>
            <div className={styles.itemBody} onClick={() => openModalTrueApi()}>
              <TokenIcon className={styles.itemIcon} />
              <span className={styles.itemText}>Токен TrueAPI</span>
            </div>
            <div
              aria-disabled={isFileDownloading}
              className={styles.itemBody}
              onClick={() => (!isFileDownloading ? dispatch(postDownloadBillingFileRequest()) : () => undefined)}
            >
              <PaidIcon className={styles.itemIcon} />
              <span className={styles.itemText}>Білінг</span>
            </div>
          </div>
          <div className={styles.item}>
            <span className={styles.itemHeader}>Програми для скачування</span>
            <a href={`${lPrintLink}`} className={styles.link}>
              <div className={styles.itemBody} onClick={() => undefined}>
                <LaptopIcon className={styles.itemIcon} />
                <span className={styles.itemText}>Програма Label Print</span>
              </div>
            </a>
            <a href={`${reaJetLink}`} className={styles.link}>
              <div className={styles.itemBody} onClick={() => undefined}>
                <LaptopIcon className={styles.itemIcon} />
                <span className={styles.itemText}>Програма REA Print</span>
              </div>
            </a>
            <a href={`${neriLink}`} className={styles.link}>
              <div className={styles.itemBody} onClick={() => undefined}>
                <LaptopIcon className={styles.itemIcon} />
                <span className={styles.itemText}>Програма NERI Print</span>
              </div>
            </a>
            <a href={`${as1000Link}`} className={styles.link}>
              <div className={styles.itemBody} onClick={() => undefined}>
                <LaptopIcon className={styles.itemIcon} />
                <span className={styles.itemText}>Програма станції агрегації AS1000</span>
              </div>
            </a>
            <div className={styles.itemBody} onClick={openModal}>
              <InstallMobileIcon className={styles.itemIcon} />
              <span className={styles.itemText}>Програма Mobile Gateway</span>
            </div>
            <a href={`${toolsLink}`} className={styles.link}>
              <div className={styles.itemBody} onClick={() => undefined}>
                <HandymanIcon className={styles.itemIcon} />
                <span className={styles.itemText}>Інструменти та утіліти</span>
              </div>
            </a>
          </div>
          <div className={styles.item}>
            <span className={styles.itemHeader}>Про програму</span>
            <div className={styles.itemBody} onClick={() => navigate('/contacts')}>
              <PhoneForwardedIcon className={styles.itemIcon} />
              <span className={styles.itemText}>Контакти служби підтримки</span>
            </div>
            <a href={`${manualsLink}`} className={styles.link}>
              <div className={styles.itemBody} onClick={() => undefined}>
                <HelpIcon className={styles.itemIcon} />
                <span className={styles.itemText}>Інструкції користувача</span>
              </div>
            </a>
            <a href={`${videoLink}`} className={styles.link}>
              <div className={styles.itemBody} onClick={() => undefined}>
                <VideoCameraBackIcon className={styles.itemIcon} />
                <span className={styles.itemText}>Відеоінструкції</span>
              </div>
            </a>
          </div>
        </section>
      </div>
      <DownloadMobileGatewayModal isShow={isShow} onCloseModal={closeModal} />
      <SelectCidDialog open={open} openComplianceModal={() => setIsComplianceModalShow(true)} setOpen={setOpen} />
      <ComplianceModal
        isShown={isComplianceModalShow}
        closeModal={() => setIsComplianceModalShow(false)}
        email={account.username}
      />
      {isShowTrueApi && <TrueAPIModal isShow={isShowTrueApi} onCloseModal={closeModalTrueApi} />}
    </div>
  );
};
