import styles from './SeriesReport.module.scss';
import { Spinner } from '../../common/Spinner/Spinner';
import React, { useEffect, useState } from 'react';
import { Button, OutlinedInput } from '@mui/material';
import InputSearch from '../../common/InputSearch';
import SeriesReportTable from '../../common/SeriesReportTable';
import { useDispatch, useSelector } from 'react-redux';
import { getSeriesReportRequest } from './services/actions';
import { seriesReportSelector } from './services/selector';
import ErrorResponse from '../../shared/ErrorResponse';
import { useNavigate } from 'react-router-dom';
import { inputStyle } from './services/style';

export const SeriesReport = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [seriesValue, setSeriesValue] = useState<any>('');

  const { loading, seriesReportResponse, seriesReportErrorResponses, isFileDownloading } =
    useSelector(seriesReportSelector);

  const [tableData, setTableData] = useState<any[]>([seriesReportResponse]);

  const showHandler = () => {
    dispatch(getSeriesReportRequest({ series: seriesValue }));
  };

  useEffect(() => {
    setTableData([]);
  }, []);

  useEffect(() => {
    if (Object.keys(seriesReportErrorResponses).length !== 0 && seriesReportErrorResponses?.status === 403) {
      navigate('/error');
    }
  }, [seriesReportErrorResponses, navigate]);

  return (
    <div className={styles.wrapper}>
      {Object.keys(seriesReportErrorResponses).length !== 0 ? (
        <ErrorResponse error={seriesReportErrorResponses} />
      ) : null}
      <div className={styles.loadingGroup}>
        <span className={styles.title}>Звіти по серіям</span>
        {loading ? <Spinner /> : null}
      </div>
      <span>Детальний перегляд друку маркувань, агрегацій та відвантажень по серіям</span>
      <div className={styles.filterBlock}>
        <div className={styles.firstRow}>
          <div className={styles.calendarItem}>
            <span>Серія:</span>
            <OutlinedInput
              name="Series"
              value={seriesValue}
              onChange={(event) => setSeriesValue(event.target.value)}
              sx={inputStyle}
              autoComplete="off"
              inputProps={{ maxLength: 20 }}
            />
          </div>
          <Button
            variant="contained"
            className={styles.btn}
            onClick={showHandler}
            disabled={seriesValue.trim().length === 0}
          >
            <span className={styles.btnText}>Пошук</span>
          </Button>
        </div>
        <div className={styles.secondRow}>
          <div className={styles.searchGroup}>
            <span>Фільтр по всім полям:</span>
            <InputSearch setTableData={setTableData} data={seriesReportResponse} />
          </div>
        </div>
      </div>
      <SeriesReportTable data={tableData} isFileDownloading={isFileDownloading} />
    </div>
  );
};
