import { call, put, takeLatest } from 'redux-saga/effects';
import { AxiosResponse } from 'axios';
import {
  GET_USER_REQUEST,
  POST_AUTHENTICATION_LOGIN_REQUEST,
  POST_AUTHENTICATION_LOGOUT_REQUEST,
  POST_DOWNLOAD_BILLING_FILE_REQUEST,
} from './constants';
import {
  getUserError,
  getUserSuccess,
  postAuthenticationLoginError,
  postAuthenticationLogoutError,
  postAuthenticationLogoutRequest,
  postDownloadBillingFileSuccess,
  postDownloadBillingFileError,
} from './actions';
import { User } from '../../../entities/User/service';

function* getUserRequestSaga() {
  try {
    const response: AxiosResponse = yield call(User.getUser);
    const responseRole = response.data.ResponseBody;
    yield put(getUserSuccess(responseRole));
  } catch (e: any) {
    const error = e.toJSON();
    yield put(getUserError(error));
  }
}

function* postAuthenticationLoginRequestSaga() {
  try {
    yield call(User.postLogin);
  } catch (e: any) {
    const error = e.toJSON();
    yield put(postAuthenticationLoginError(error));
  }
}

function* postAuthenticationLogoutRequestSaga(action: ReturnType<typeof postAuthenticationLogoutRequest>) {
  try {
    const response: AxiosResponse = yield call(User.postLogout);

    if (response) {
      localStorage.removeItem('cid');
      localStorage.removeItem('entraGroupId');
      localStorage.removeItem('account');
      localStorage.removeItem('isComplianceAccepted');

      action.payload();
    }
  } catch (e: any) {
    const error = e.toJSON();
    yield put(postAuthenticationLogoutError(error));
    action.payload();
  }
}

function* postDownloadBillingFileRequestSaga() {
  try {
    yield call(User.postBilling);
    yield put(postDownloadBillingFileSuccess({}));
  } catch (e: any) {
    const error = e.toJSON();
    yield put(postDownloadBillingFileError(error));
  }
}

export function* UserSagaWatcher() {
  yield takeLatest(GET_USER_REQUEST, getUserRequestSaga);
  yield takeLatest(POST_AUTHENTICATION_LOGIN_REQUEST, postAuthenticationLoginRequestSaga);
  yield takeLatest(POST_AUTHENTICATION_LOGOUT_REQUEST, postAuthenticationLogoutRequestSaga);
  yield takeLatest(POST_DOWNLOAD_BILLING_FILE_REQUEST, postDownloadBillingFileRequestSaga);
}
