import { AxiosResponse } from 'axios';
import { instance as axios } from 'api/axios';

export class User {
  public static async getUser() {
    const url = '/User';
    const response: AxiosResponse = await axios.get(url);
    return response;
  }

  public static async postLogin() {
    const url = '/Authentication/Login';
    const response: AxiosResponse = await axios.post(url);
    return response;
  }

  public static async postLogout() {
    const url = '/Authentication/Logout';
    const response: AxiosResponse = await axios.post(url);
    return response;
  }

  public static async postBilling() {
    const requestUrl = '/Report/Billing/Excel/File';
    const res = await axios({
      url: `${requestUrl}`,
      method: 'POST',
      responseType: 'blob',
    }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `Billing.xlsx`);
      document.body.appendChild(link);
      link.click();
    });
    return res;
  }
}
