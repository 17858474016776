import { DataGrid, GridRenderCellParams, ukUA } from '@mui/x-data-grid';
import { Tooltip } from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';
import * as React from 'react';
import { useDispatch } from 'react-redux';
import { getExcelFileSeriesRequest } from '../../EventsLog/SeriesReport/services/actions';
import styles from '../DataTable/DataTable.module.scss';
import { style } from '../DataTable/style';
import MenuIcon from '@mui/icons-material/Menu';
import { ReprintModal } from './components/ReprintModal';
import useModal from '../../shared/hooks/useModal/useModal';

interface Props {
  data: any;
  isFileDownloading: boolean;
}

export const SeriesReportTable: React.FC<Props> = ({ data, isFileDownloading }) => {
  const dispatch = useDispatch();
  const [pageSize, setPageSize] = React.useState(15);
  const [selectedRow, setSelectedRow] = React.useState<any>();
  const { isShow, openModal, closeModal } = useModal();

  const seriesGrid = [
    { field: 'series', headerName: 'Серія', width: 140 },
    { field: 'gtin', headerName: 'Код GTIN', width: 130 },
    { field: 'countryCode', headerName: 'Країна', width: 80 },
    { field: 'internalProductName', headerName: 'Внутрішня назва продукції', width: 330, flex: 1 },
    { field: 'booked', headerName: 'Заброньовано, шт.', width: 120 },
    { field: 'printed', headerName: 'Надруковано,  шт.', width: 120 },
    { field: 'aggregated', headerName: 'Агреговано, шт.', width: 110 },
    { field: 'withdrawals', headerName: 'Вилучено, шт.', width: 100 },
    { field: 'confirmedBoxes', headerName: 'Підтверджено, ящ.', width: 130 },
    { field: 'checkedBoxes', headerName: 'Відвантаження дозволено, ящ.', width: 130 },
    { field: 'shippedBoxes', headerName: 'Відвантажено, ящ.', width: 130 },
    { field: 'shippedPallets', headerName: 'Відвантажено палет', width: 140 },
    { field: 'receiptIds', headerName: '', hide: true },
    {
      field: 'button',
      headerName: 'Файли',
      width: 70,
      align: 'right',
      renderCell: (params: GridRenderCellParams<Date>) => (
        <>
          <MenuIcon
            className={styles.icon}
            onClick={() => {
              setSelectedRow({
                GTIN: params.row.gtin,
                series: params.row.series,
              });
              openModal();
            }}
          />
          <Tooltip title="Скачати як звіт в Excel" placement="top">
            <a
              aria-disabled={isFileDownloading}
              onClick={() =>
                !isFileDownloading
                  ? dispatch(
                      getExcelFileSeriesRequest({
                        receiptIds: params.row.receiptIds,
                        series: params.row.series,
                      }),
                    )
                  : () => undefined
              }
            >
              <DownloadIcon className={styles.icon} />
            </a>
          </Tooltip>
        </>
      ),
    },
  ];

  return (
    <>
      <div style={{ height: 599, width: '100%', marginTop: '20px' }}>
        <DataGrid
          localeText={ukUA.components.MuiDataGrid.defaultProps.localeText}
          rows={data}
          headerHeight={34}
          rowHeight={34}
          pageSize={pageSize}
          onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
          pagination
          // @ts-expect-error
          columns={seriesGrid}
          getRowId={() => Math.random()}
          disableSelectionOnClick
          disableColumnMenu
          disableColumnSelector
          sx={style}
          componentsProps={{
            pagination: {
              SelectProps: {
                MenuProps: {
                  sx: {
                    '& .MuiMenuItem-root': {
                      fontSize: 14,
                      bgcolor: '#ffffff',
                    },
                  },
                  PaperProps: {
                    sx: {
                      bgcolor: '#ffffff',
                    },
                  },
                },
              },
            },
          }}
        />
      </div>

      <ReprintModal isShown={isShow} closeModal={closeModal} params={selectedRow} />
    </>
  );
};
