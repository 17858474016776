import { useTranslation } from 'react-i18next';
import CloseIcon from '@mui/icons-material/Close';
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';
import SaveIcon from '@mui/icons-material/Save';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { Box, Button, MenuItem, Modal, TextField, Typography, Tooltip } from '@mui/material';
import { Formik } from 'formik';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useModal from '../../shared/hooks/useModal/useModal';
import {
  putUpdateBoxTemplatesRequest,
  putUpdateBoxTemplatesSuccess,
} from '../../Templates/BoxTemplates/services/actions';
import { boxTemplateSelector } from '../../Templates/BoxTemplates/services/selector';
import {
  putUpdatePalletTemplatesRequest,
  putUpdatePalletTemplatesSuccess,
} from '../../Templates/PalletTemplates/services/actions';
import { palletTemplateSelector } from '../../Templates/PalletTemplates/services/selector';
import {
  putUpdateUnitTemplatesRequest,
  putUpdateUnitTemplatesSuccess,
} from '../../Templates/UnitTemplates/services/actions';
import { unitTemplateSelector } from '../../Templates/UnitTemplates/services/selector';
import { ImageViewerModal } from '../ImageViewerModal/ImageViewerModal';
import SelectField from '../SelectField';
import SimpleField from '../SimpleField';

import styles from './AddTemplateModal.module.scss';

import { countryListSelector } from '../../../entities/Countries/selector';

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 900,
  bgcolor: '#ECEFF1 !important',
  boxShadow: 24,
  p: 4,
  fontSize: '13px !important',
};

const textFieldStyle = {
  bgcolor: '#FFE5B4',
  '.MuiOutlinedInput-notchedOutline': {
    borderColor: '#B0BEC5',
    // height: '65%',
  },
  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
    borderColor: '#B0BEC5',
  },
  '&:hover .MuiOutlinedInput-notchedOutline': {
    borderColor: '#B0BEC5',
  },
  '& .MuiOutlinedInput-root': {
    fontSize: '13px !important',
    '& fieldset': {
      borderColor: '#B0BEC5',
    },
    '&:hover fieldset': {
      borderColor: '#B0BEC5',
    },
    '&.Mui-focused fieldset': {
      borderColor: '#B0BEC5',
    },
  },
};

interface Props {
  isShown: boolean;
  level: number;
  data: any;
  closeModal: () => void;
}

export const AddTemplateModal: React.FC<Props> = ({ isShown, closeModal, level, data }) => {
  const dispatch = useDispatch();
  const { isShow: isShownViewer, openModal: openViewerModal, closeModal: closeViewerModal } = useModal();

  const { updatePalletResponse } = useSelector(palletTemplateSelector);
  const { updateBoxResponse } = useSelector(boxTemplateSelector);
  const { updateUnitResponse } = useSelector(unitTemplateSelector);

  const { t } = useTranslation();

  const onSaveHandler = (values: any) => {
    if (level === 0) {
      dispatch(putUpdateUnitTemplatesRequest({ ...values, templateName: values?.templateName.trim(), level }));
    }
    if (level === 3) {
      dispatch(putUpdatePalletTemplatesRequest({ ...values, templateName: values?.templateName.trim(), level }));
    }
    if (level === 2) {
      dispatch(putUpdateBoxTemplatesRequest({ ...values, templateName: values?.templateName.trim(), level }));
    }
  };

  const onCloseHandler = () => {
    dispatch(putUpdatePalletTemplatesSuccess({}));
    dispatch(putUpdateUnitTemplatesSuccess({}));
    dispatch(putUpdateBoxTemplatesSuccess({}));
    closeModal();
  };

  const { countries } = useSelector(countryListSelector);

  useEffect(() => {
    if (Object.keys(updatePalletResponse).length !== 0 && updatePalletResponse?.messages?.Successful !== null) {
      closeModal();
    }
  }, [updatePalletResponse]);
  useEffect(() => {
    if (Object.keys(updateBoxResponse).length !== 0 && updateBoxResponse?.messages?.Successful !== null) {
      closeModal();
    }
  }, [updateBoxResponse]);
  useEffect(() => {
    if (Object.keys(updateUnitResponse).length !== 0 && updateUnitResponse?.messages?.Successful !== null) {
      closeModal();
    }
  }, [updateUnitResponse]);

  return (
    <div>
      {Object.keys(data).length !== 0 ? (
        <>
          <Modal
            open={isShown}
            onClose={() => {
              closeModal();
              dispatch(putUpdatePalletTemplatesSuccess({}));
              dispatch(putUpdateUnitTemplatesSuccess({}));
              dispatch(putUpdateBoxTemplatesSuccess({}));
            }}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style}>
              <div className={styles.menu}>
                {level === 2 ? (
                  <Typography id="modal-modal-title" variant="h6" component="h2">
                    Новий шаблон агрегаційної етикетки ящика
                    <Tooltip
                      title={
                        'Список доступних змінних: $ean$ - значення GTIN; $qty$ - кількість одиниць в агрегаті; $series$ - значення серії; $expDateDD$ - значення DD Спожити до упаковка; $expDateMM$ - значення MM Спожити до упаковка; $expDateYY$  - значення YY Спожити до упаковка; $expDateYYYY$  - значення YYYY Спожити до упаковка; $ProductNameExternal$ - експортна назва продукції даної країни; $country$ - код країни; $sscc$ - значення SSCC.'
                      }
                      placement="right"
                    >
                      <HelpOutlineIcon className={styles.helper} />
                    </Tooltip>
                  </Typography>
                ) : null}
                {level === 3 ? (
                  <Typography id="modal-modal-title" variant="h6" component="h2">
                    Новий шаблон агрегаційної етикетки палети
                    <Tooltip
                      title={
                        'Список доступних змінних: $boxamount$ - кількість ящиків в агрегаті; $type$ - тип палети; $brutt$ - вага БРУТТО, кг; $PackagingDate$ - дата пакування; $country$ - код країни; $sscc$ - значення SSCC; $userName$ - пакувальник; $purchaseOrder$ - номер замовлення чи інвойсу.'
                      }
                      placement="right"
                    >
                      <HelpOutlineIcon className={styles.helper} />
                    </Tooltip>
                  </Typography>
                ) : null}
                {level === 0 ? (
                  <Typography id="modal-modal-title" variant="h6" component="h2">
                    Новий шаблон одиниці продукції
                    <Tooltip
                      title={
                        'Список доступних змінних:\n$gtin$	- значення GTIN;\n$serialNumber$ - значення серійного номера;\n$mrp$	- значення МРЦ;\n$expDateDD$ - значення DD Спожити до упаковка;\n$expDateMM$ - значення MM Спожити до упаковка;\n$expDateYY$  - значення YY Спожити до упаковка;\n$expDateYYYY$  - значення YYYY Спожити до упаковка;\n$mfg$ - значення Дата виробництва (MMYYYY);\n$series$ - значення серії;\n$ai91$ - значення блоку 91;\n$ai92$ - значення блоку 92;\n$ai17$ - значення дати Спожити до в форматі YYMMDD'
                      }
                      placement="right"
                    >
                      <HelpOutlineIcon className={styles.helper} />
                    </Tooltip>
                  </Typography>
                ) : null}
                <CloseIcon className={styles.icon} onClick={onCloseHandler} />
              </div>
              <Formik initialValues={data} onSubmit={() => undefined}>
                {({ values, handleSubmit, setFieldValue }) => (
                  <form onSubmit={handleSubmit}>
                    <div className={styles.header}>
                      <div className={styles.wrapper}>
                        <span className={styles.title}>
                          Найменування шаблону (ID {values.id}) <em className={styles.required}>*</em>
                        </span>
                        <SimpleField
                          name="templateName"
                          hasMarginTop={false}
                          value={values.templateName}
                          validate={true}
                          maxLength={30}
                        />
                      </div>
                      <div className={styles.wrapper}>
                        <span className={styles.title}>
                          {t('printLang')} <em className={styles.required}>*</em>
                        </span>
                        <SelectField
                          title=""
                          name="lang"
                          value={values.lang}
                          variant="noLabel"
                          error={values.lang === null}
                          size="small"
                        >
                          <MenuItem value="ZPL" sx={{ fontSize: '13px' }}>
                            ZPL
                          </MenuItem>
                          <MenuItem value="DPL" sx={{ fontSize: '13px' }}>
                            DPL
                          </MenuItem>
                          <MenuItem value="XML" sx={{ fontSize: '13px' }}>
                            XML
                          </MenuItem>
                          <MenuItem value="DP" sx={{ fontSize: '13px' }}>
                            DP
                          </MenuItem>
                          {level === 0 ? (
                            <MenuItem value="REA" sx={{ fontSize: '13px' }}>
                              REA
                            </MenuItem>
                          ) : null}
                          {level === 0 ? (
                            <MenuItem value="WOLKE" sx={{ fontSize: '13px' }}>
                              WOLKE
                            </MenuItem>
                          ) : null}
                        </SelectField>
                      </div>
                      {level !== 2 ? (
                        <div className={styles.wrapper}>
                          <span className={styles.title}>
                            Країна <em className={styles.required}>*</em>
                          </span>
                          <SelectField
                            title={''}
                            variant="noLabel"
                            name="countryCodeId"
                            size="small"
                            value={values.countryCodeId}
                            dropdownValues={countries}
                            error={values.countryCodeId === null}
                          >
                            {countries.map((country: any) => (
                              <MenuItem value={country.id} sx={{ fontSize: '13px' }}>
                                {country.code}, {country.countryName}
                              </MenuItem>
                            ))}
                          </SelectField>
                        </div>
                      ) : null}
                    </div>
                    <div className={styles.iconViewer}>
                      <span className={styles.title}>Фото шаблону:</span>
                      <Tooltip title={'Обрати фото'} placement="right">
                        <AddPhotoAlternateIcon onClick={openViewerModal} className={styles.icon} />
                      </Tooltip>
                    </div>
                    <div className={styles.body}>
                      <span className={styles.title}>Код шаблону:</span>
                      <TextField
                        name="value"
                        id="outlined-multiline-static"
                        label=""
                        inputProps={{ style: { fontSize: 14, fontFamily: 'monospace', lineHeight: 1 } }}
                        sx={textFieldStyle}
                        onChange={(e) => setFieldValue('value', e.target.value)}
                        multiline
                        rows={30}
                        value={values.value}
                      />
                    </div>
                    {level === 0 ? (
                      <div className={styles.wrapper}>
                        <span className={styles.title}>Коментар майстру:</span>
                        <SimpleField
                          name="comment"
                          maxLength={60}
                          hasMarginTop={false}
                          value={values.comment}
                          validate={false}
                        />
                      </div>
                    ) : null}
                    <section className={styles.btnSection}>
                      <>
                        {Object.keys(updateUnitResponse).length !== 0 &&
                        updateUnitResponse?.messages?.Failed !== null ? (
                          <span className={styles.errorMessage}>{updateUnitResponse?.messages?.Failed[0]}</span>
                        ) : Object.keys(updatePalletResponse).length !== 0 &&
                          updatePalletResponse?.messages?.Failed !== null ? (
                          <span className={styles.errorMessage}>{updatePalletResponse?.messages?.Failed[0]}</span>
                        ) : (
                          <span className={styles.errorMessage}>{updateBoxResponse?.messages?.Failed[0]}</span>
                        )}
                      </>
                      <div>
                        <Button
                          className={styles.saveBtn}
                          variant="contained"
                          disabled={
                            values.templateName === null ||
                            values.templateName.trim().length === 0 ||
                            values.lang === null ||
                            (values.countryCodeId === null && level === 0) ||
                            values.value === null
                          }
                          onClick={() => onSaveHandler(values)}
                        >
                          <span className={styles.btnText}>Зберегти</span>
                          <SaveIcon className={styles.icon} />
                        </Button>
                      </div>
                    </section>
                  </form>
                )}
              </Formik>
            </Box>
          </Modal>
          <ImageViewerModal
            isShownViewer={isShownViewer}
            closeViewerModal={closeViewerModal}
            extended={true}
            labelTemplateId={data?.id}
          />
        </>
      ) : null}
    </div>
  );
};
