import {
  GET_USER_REQUEST,
  GET_USER_SUCCESS,
  GET_USER_ERROR,
  CLOSE_ERROR_POPUP_MAIN,
  POST_AUTHENTICATION_LOGIN_REQUEST,
  POST_AUTHENTICATION_LOGIN_SUCCESS,
  POST_AUTHENTICATION_LOGIN_ERROR,
  POST_AUTHENTICATION_LOGOUT_REQUEST,
  POST_AUTHENTICATION_LOGOUT_SUCCESS,
  POST_AUTHENTICATION_LOGOUT_ERROR,
  POST_DOWNLOAD_BILLING_FILE_REQUEST,
  POST_DOWNLOAD_BILLING_FILE_SUCCESS,
  POST_DOWNLOAD_BILLING_FILE_ERROR,
} from './constants';

export const getUserRequest = () => ({
  type: GET_USER_REQUEST,
});
export const getUserSuccess = (data: any) => ({
  type: GET_USER_SUCCESS,
  payload: data,
});
export const getUserError = (error: any) => ({
  type: GET_USER_ERROR,
  payload: error,
});

export const postAuthenticationLoginRequest = () => ({
  type: POST_AUTHENTICATION_LOGIN_REQUEST,
});
export const postAuthenticationLoginSuccess = (data: any) => ({
  type: POST_AUTHENTICATION_LOGIN_SUCCESS,
  payload: data,
});
export const postAuthenticationLoginError = (error: any) => ({
  type: POST_AUTHENTICATION_LOGIN_ERROR,
  payload: error,
});

export const postAuthenticationLogoutRequest = (callback: () => void) => ({
  type: POST_AUTHENTICATION_LOGOUT_REQUEST,
  payload: callback,
});
export const postAuthenticationLogoutSuccess = (data: any) => ({
  type: POST_AUTHENTICATION_LOGOUT_SUCCESS,
  payload: data,
});
export const postAuthenticationLogoutError = (error: any) => ({
  type: POST_AUTHENTICATION_LOGOUT_ERROR,
  payload: error,
});

export const postDownloadBillingFileRequest = () => ({
  type: POST_DOWNLOAD_BILLING_FILE_REQUEST,
});
export const postDownloadBillingFileSuccess = (data: any) => ({
  type: POST_DOWNLOAD_BILLING_FILE_SUCCESS,
  payload: data,
});
export const postDownloadBillingFileError = (error: any) => ({
  type: POST_DOWNLOAD_BILLING_FILE_ERROR,
  payload: error,
});

export const closeErrorPopupMain = () => ({
  type: CLOSE_ERROR_POPUP_MAIN,
});

export type Actions =
  | ReturnType<typeof postDownloadBillingFileRequest>
  | ReturnType<typeof postDownloadBillingFileSuccess>
  | ReturnType<typeof postDownloadBillingFileError>
  | ReturnType<typeof postAuthenticationLoginRequest>
  | ReturnType<typeof postAuthenticationLoginSuccess>
  | ReturnType<typeof postAuthenticationLoginError>
  | ReturnType<typeof postAuthenticationLogoutRequest>
  | ReturnType<typeof postAuthenticationLogoutSuccess>
  | ReturnType<typeof postAuthenticationLogoutError>
  | ReturnType<typeof closeErrorPopupMain>
  | ReturnType<typeof getUserRequest>
  | ReturnType<typeof getUserSuccess>
  | ReturnType<typeof getUserError>;
