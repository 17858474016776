import { Button, MenuItem, SelectChangeEvent } from '@mui/material';
import { Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useTimer } from 'use-timer';
import { getCountryListRequest } from '../../../entities/Countries/actions';
import { countryListSelector } from '../../../entities/Countries/selector';
import { ImportCodesState } from '../../../entities/ImportCodes/interfaces';
import ImportFile from '../../common/ImportFile';
import InputField from '../../common/InputField';
import SelectField from '../../common/CountrySelectField';
import ErrorResponse from '../../shared/ErrorResponse';

import styles from './ImportCodes.module.scss';
import {
  getExternalStatusesRequest,
  postGenerateCodesRequest,
  postGenerateCodesSuccess,
  postImportFileRequest,
  postValidateFileRequest,
} from './services/actions';
import { importCodesSelector } from './services/selector';
import { useNavigate } from 'react-router-dom';
import { Spinner } from '../../common/Spinner/Spinner';

export const ImportCodes: React.FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const initialData: ImportCodesState = {
    countryCode: 'AZ',
    externalStatusId: 1,
    relatedDocumentNum: '',
    gtin: null,
    codesCount: null,
  };

  const [file, setFile] = useState(null);
  const [disableApplyBtn, setDisableApplyBtn] = useState(true);
  const [showApplySection, setShowApplySection] = useState(false);
  const [responseSection, setResponseSection] = useState(false);
  const [generateSection, setGenerateSection] = useState(false);
  const [initialValue, setInitialValue] = useState<any>(initialData);
  const { time, start, pause, reset } = useTimer();

  const { t } = useTranslation();

  const {
    loading,
    statuses,
    validateFileResponse,
    validateFileErrorResponse,
    applyFileResponse,
    applyFileErrorResponse,
    getStatusesErrorResponse,
    generateCodesResponse,
    generateCodesStartLoading,
  } = useSelector(importCodesSelector);
  const { countries } = useSelector(countryListSelector);

  useEffect(() => {
    dispatch(getExternalStatusesRequest());
    dispatch(getCountryListRequest());
    setDisableApplyBtn(true);
    setResponseSection(false);
    setGenerateSection(false);
  }, []);

  useEffect(() => {
    setResponseSection(false);
    reset();
  }, [file]);

  useEffect(() => {
    if (Object.keys(validateFileResponse).length !== 0) {
      pause();
    }
    if (Object.keys(validateFileErrorResponse).length !== 0) {
      pause();
    }
  }, [validateFileErrorResponse]);

  const generateGTIN = (values: ImportCodesState) => {
    if (!values.countryCode) {
      return;
    }
    start();
    setGenerateSection(true);
    dispatch(
      postGenerateCodesRequest({
        gtin: values.gtin,
        codesCount: values.codesCount,
        countryCode: values.countryCode,
      }),
    );
  };

  const validateFile = (values: ImportCodesState) => {
    if (!values.countryCode) {
      return;
    }

    start();
    setResponseSection(true);
    dispatch(
      postValidateFileRequest({
        relatedDocumentNum: values.relatedDocumentNum,
        countryCode: values.countryCode,
        externalStatusId: values.externalStatusId,
        file,
      }),
    );
  };

  const applyFile = () => {
    dispatch(postImportFileRequest({ operationId: validateFileResponse.messages?.Successful.dbOperationId }));
    setShowApplySection(true);
  };

  useEffect(() => {
    pause();
    if (Object.keys(validateFileResponse).length !== 0 && validateFileResponse?.messages?.Successful !== null) {
      setDisableApplyBtn(false);
    } else {
      setDisableApplyBtn(true);
    }
  }, [validateFileResponse]);

  useEffect(() => {
    if (Object.keys(getStatusesErrorResponse).length !== 0 && getStatusesErrorResponse?.status === 403) {
      navigate('/error');
    }
  }, [getStatusesErrorResponse]);

  useEffect(() => {
    if (!generateSection) {
      dispatch(postGenerateCodesSuccess({}));
    }
  }, [generateSection]);

  useEffect(() => {
    if (countries.length !== 0) {
      setInitialValue({
        countryCode: countries[0].countryCode,
        externalStatusId: 1,
        relatedDocumentNum: '',
        gtin: null,
        codesCount: null,
      });
    }
  }, [countries]);

  const getSelfGenerageFormFields = (values: any) => (
    <>
      <InputField
        name="gtin"
        type="number"
        title="GTIN (13 цифр, без першого 0 )"
        maxLength={13}
        value={values.gtin}
        validate
        required
        disabled={generateCodesStartLoading}
      />
      <InputField
        name="codesCount"
        type="number"
        title="Кількість, шт (макс. 30000)"
        maxLength={5}
        value={values.codesCount}
        validate
        required
        disabled={generateCodesStartLoading}
      />
    </>
  );

  const getUZFormFields = (values: any) => (
    <>
      <SelectField
        name="externalStatusId"
        title={t('bankCodesOrderStatus')}
        value={values.externalStatusId}
        dropdownValues={statuses}
        error={values.externalStatusId === null}
        disabled
      >
        {statuses.map((status: any) => (
          <MenuItem key={status.name} value={status.number} sx={{ fontSize: '13px' }}>
            {status.description}
          </MenuItem>
        ))}
      </SelectField>
      <InputField
        name="relatedDocumentNum"
        title={t('bankCodesNumOfOrder')}
        maxLength={40}
        value={values.relatedDocumentNum}
        validate
        disabled={loading}
      />
      <ImportFile file={file} fileHandler={setFile} setShowApplySection={setShowApplySection} />
    </>
  );

  return (
    <div className={styles.wrapper}>
      {Object.keys(validateFileErrorResponse).length !== 0 ? <ErrorResponse error={validateFileErrorResponse} /> : null}
      {Object.keys(applyFileErrorResponse).length !== 0 ? <ErrorResponse error={applyFileErrorResponse} /> : null}
      {Object.keys(getStatusesErrorResponse).length !== 0 ? <ErrorResponse error={getStatusesErrorResponse} /> : null}
      <Formik initialValues={initialValue} onSubmit={() => undefined} enableReinitialize>
        {({ values, setFieldValue, resetForm }) => (
          <form>
            <section className={styles.codesHeader}>
              <div>
                <span className={styles.bankCodesSubTitle}>Імпорт нових кодів маркування</span>
                {loading && <Spinner />}
                {generateCodesStartLoading && <Spinner />}
              </div>
              <span className={styles.bankCodesAdditionalText}>{t('bankCodesAdditionalTextImport')}</span>
            </section>
            <section className={styles.codesForm}>
              <SelectField
                name="countryCode"
                title={t('bankCodesCountry')}
                value={values.countryCode}
                dropdownValues={countries}
                error={values.countryCode === ''}
                disabled={loading || generateCodesStartLoading}
                handleChangeSelect={(e: SelectChangeEvent<any>) => {
                  resetForm();
                  setFieldValue('countryCode', e.target.value);
                  setFieldValue('gtin', '');
                  setFieldValue('codesCount', '');
                  setFieldValue('relatedDocumentNum', '');
                  setShowApplySection(false);
                  setGenerateSection(false);
                  setResponseSection(false);
                }}
              >
                {countries.map((country: any) => (
                  <MenuItem value={country.code} sx={{ fontSize: '13px' }}>
                    {country.code}, {country.countryName}
                  </MenuItem>
                ))}
              </SelectField>
              {values.countryCode === 'UZ' || values.countryCode === 'KZ'
                ? getUZFormFields(values)
                : getSelfGenerageFormFields(values)}
            </section>
            <section className={styles.importFileSection}>
              {values.countryCode === 'UZ' || values.countryCode === 'KZ' ? (
                <>
                  <div className={styles.btnGroup}>
                    <Button
                      variant="contained"
                      className={styles.btn}
                      disabled={
                        loading ||
                        file === null ||
                        values.externalStatusId === null ||
                        values.relatedDocumentNum.toString().trim().length === 0 ||
                        Object.keys(validateFileResponse).length !== 0 ||
                        Object.keys(validateFileErrorResponse).length !== 0
                      }
                      onClick={() => validateFile(values)}
                    >
                      <span className={styles.btnTex}>Крок 1 (валідація)</span>
                    </Button>
                  </div>
                  <div className={styles.btnGroup}>
                    <Button
                      variant="contained"
                      className={styles.btn}
                      disabled={
                        loading ||
                        disableApplyBtn ||
                        Object.keys(applyFileErrorResponse).length !== 0 ||
                        Object.keys(applyFileResponse).length !== 0
                      }
                      onClick={() => applyFile()}
                    >
                      <span className={styles.btnTex}>Крок 2 (імпорт)</span>
                    </Button>
                  </div>
                </>
              ) : (
                <div className={styles.btnGroup}>
                  <Button
                    variant="contained"
                    className={styles.btn}
                    disabled={
                      values?.gtin?.toString().trim().length !== 13 ||
                      values?.codesCount?.toString().trim().length === 0 ||
                      generateCodesStartLoading
                    }
                    onClick={() => generateGTIN(values)}
                  >
                    <span className={styles.btnTex}>Згенерувати</span>
                  </Button>
                </div>
              )}
            </section>
            {generateSection && (
              <section className={styles.generateSection}>
                <span>Виконується генерація...</span>
                {Object.keys(generateCodesResponse).length ? <span>Завершено.</span> : <span />}
                {generateCodesResponse?.Result === 1 ? (
                  <span>{generateCodesResponse?.data?.ResponseBody?.messages?.Successful[0]}</span>
                ) : (
                  <span className={styles.failed}>
                    {generateCodesResponse?.data?.ResponseBody?.messages?.Failed[0]}
                  </span>
                )}
              </section>
            )}
            {responseSection && file !== null ? (
              <>
                <section className={styles.timerSection}>
                  <span className={styles.timer}>
                    Триває валідація... {time} сек.
                    {Object.keys(validateFileResponse).length !== 0 &&
                    validateFileResponse?.messages?.Failed !== null &&
                    validateFileResponse?.messages?.Failed.length !== 0 ? (
                      <span className={styles.failedResponse}>&nbsp;{validateFileResponse?.messages?.Failed[0]}</span>
                    ) : null}
                    {Object.keys(validateFileResponse).length !== 0 &&
                    validateFileResponse?.messages?.Successful !== null &&
                    validateFileResponse?.messages?.Successful.length !== 0 ? (
                      <span className={styles.validateSuccessSection_Title}>
                        &nbsp;Знайдено {validateFileResponse?.messages?.Successful.markingCodeCount} кодів маркування
                        (GTIN {validateFileResponse?.messages?.Successful.gtin}). Виконайте крок 2.
                      </span>
                    ) : null}
                  </span>
                </section>
                {showApplySection &&
                Object.keys(applyFileErrorResponse).length === 0 &&
                Object.keys(validateFileResponse).length !== 0 ? (
                  <section className={styles.applySection}>
                    <span className={styles.timer}>
                      Триває імпорт...
                      {applyFileResponse?.messages?.Failed !== null &&
                      applyFileResponse?.messages?.Failed.length !== 0 ? (
                        <span className={styles.failed}>&nbsp;{applyFileResponse?.messages?.Failed[0]}</span>
                      ) : null}
                      {applyFileResponse?.messages?.Successful !== null &&
                      applyFileResponse?.messages?.Successful.length !== 0 ? (
                        <span>&nbsp;{applyFileResponse?.messages?.Successful}</span>
                      ) : null}
                    </span>
                  </section>
                ) : null}
              </>
            ) : null}
          </form>
        )}
      </Formik>
    </div>
  );
};
